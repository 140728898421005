//Library link : https://www.npmjs.com/package/react-flag-icon-css

const LANGUAGES = [
    {
      code: 'en-US',
      name: 'English',
    },
    {
      code: 'fr-FR',
      name: 'Français',
    },
  ];

export default LANGUAGES;
